<template>
  <div>
    <a-page-header
      class="page-header"
      :ghost="false"
      style="margin-bottom: 16px"
    >
      <template #title>
        <a-space size="middle">
          <a-icon type="compass"></a-icon>
          <span>应用</span>
        </a-space>
      </template>
      <span>应用即进程或者服务。</span>
    </a-page-header>

    <a-card :bordered="false">
      <application-table
        :refresh="refresh"
        :tools-list="['setting', 'reload', 'search', 'leftTool']"
      ></application-table>
    </a-card>
  </div>
</template>

<script>
import ApplicationTable from '@/components/table/ApplicationTable/index.vue'

export default {
  name: 'Application',
  components: {
    ApplicationTable
  },
  data () {
    return {
      refresh: false
    }
  }
}
</script>
